
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


.all-page {
    width: 100vw;
    height: 90vw;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding: 3rem 2%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.divisor{    
    border: 1px solid rgba(112, 112, 112, 0.418);
    border-radius: 50px;
    margin: 0;
    margin-right: 2rem;
    box-sizing: border-box;
    height: 95%;   
}

.logo{
    width: 3rem;
}

.titulo {
    font-size: 3rem;
    text-align: center;
}

.cabecalho {
    position: fixed;
    display: inline;
    top: 0;
    background-color: rgb(212, 124, 124);
    width: 150%;
    color: rgb(58, 58, 58);
    align-items: center;    
    height: 4rem;
    z-index: 10;
}

.principal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 2em; */
    margin-right: 2em;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6), 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width:70%;
}

.conteudo{    
    border-radius: 10px;
    padding: 2em;
    width: 45%;
    margin-right: 2em;
    text-justify: distribute;
}

.botao-acompanhamento{
    margin-top: 1rem;
}

.botao-denuncia{
    margin-left: auto;
}

.acompanhar {
    display: flex;
    flex-direction: row;
    margin: 1em;
    margin-top: 2em;
}

.texto-explicativo{
    margin-bottom: 1em;
}



.titulo-acompanhamento{
    margin-right: 1em;
}

.cards{
    align-items: normal;
    padding: 1rem;
}

.cards:hover{
    background-color: rgb(241, 241, 241);
}

.formulario-acompanhamento{
    display:flex;
    flex-direction: column;
    padding: 2em;
}

.conteudo-acompanhamento{
    width: 100%;
    margin: 0;
    padding: 0;
}

.link-lei{
    font-size: 0.8em;
    text-decoration: none;
}

a {
    text-decoration: none;
}