.confirmation-card {
  display: flex;
  flex-direction: column;

  width: 95%;
  max-width: 900px;

  background-color: white;
  border: 2px solid #b7b7b7;
  border-radius: 25px;

  padding: 1.5rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 725px) {
  .confirmation-card {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
