.listaDiv {
  position: relative;
  margin-top: 1rem;

  width: 95%;
  margin-left: auto;
  margin-right: auto;

  max-width: 320px;
  height: 375px;

  border-bottom: 5px solid #003399;
  transition: transform 0.1s ease-in !important;
}

.listaDiv:hover {
  transform: translateY(-10px);
}

.listaTexto > h1 {
  position: relative;
  font-size: 1.2rem;
  font-weight: 500;

  margin-bottom: 0.25rem;
}

.listaTexto > p {
  font-size: 1rem;
}

.modal-body {
  position: relative;
  background-color: white;

  border: none;
  outline: none;

  width: 95%;
  max-width: 400px;

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-body > header {
  background-color: #003399;
  padding: 1.5rem;
  width: 100%;
}

.modal-body > header > h1 {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;

  width: 100%;
}

.modal-content {
  padding: 1.5rem;
}

.content-block {
  margin-bottom: 1.5rem;
}

.content-block > h2 {
  font-size: 1.2rem;
  font-weight: 500;

  margin-bottom: 6px;
}

.content-block > p {
  font-size: 1rem;
}

.modal-body-close {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;

  z-index: 999;
}
