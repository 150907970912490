header {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

section {
  margin-bottom: 2.5rem;
}

.section-inner {
  padding: 0 0.7rem;
  margin-bottom: 1rem;
}

.form-container {
  background-color: white;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.form-title {
  margin-bottom: 3rem;
  align-self: flex-start;
}

.form-subtitle {
  max-width: 400px;

  border-bottom: 3px solid #ed3237;
  padding-bottom: 10px;
  margin-bottom: 1.5rem;
}

label {
  font-size: 16px;
  margin-right: 8px;
}

option {
  margin-bottom: 15px;
}

legend {
  color: black !important;
}

input {
  border: none;
  border-bottom: 2px solid black;
  font-size: 18px;
  padding: 5px;
}

input:focus {
  outline: none;
}

input:disabled {
  background-color: #c4c4c4;
  opacity: 60%;
  cursor: not-allowed;
}

#logo {
  width: 240px;
  height: 140px;

  margin-bottom: 1.5rem;
}

.send-button {
  width: 100%;
}

@media (min-width: 800px) {
  .send-button {
    width: auto;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

/* estilos para posicionar a logo no canto superior direito */
@media (min-width: 750px) {
  #logo {
    position: absolute;
    top: 0;
    right: 0;
  }
  .form-title {
    margin-bottom: 8rem;
  }
}
