* {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

*,
*::before,
*::after {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  /* Estilos de reset para funcionar em browsers diferentes */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 25px;
}

p {
  font-size: 20px;
}
