.pesquisa {
    position: fixed;
    top: 0;
    left: 20px;
    right: 20px;
    padding-top: 16px;
    background-color: #fff;
  }
  
  .groupItem {
    margin-top: 80px;
  }
  
  .item {
    font-size: 1.1rem;
    padding: 12px 0px;
    border-bottom: 1px solid #d4d4d4;
    cursor: pointer;
    color: #003399;
  }

  .itemVazio {
    font-size: 1.1rem;
    padding: 12px 0px;
    color: #003399;
  }
