main.portal-privacidade {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  padding: 3rem 2%;
}

main.portal-privacidade > .form {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6), 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

main.portal-privacidade > .form h1 {
  margin-bottom: 1rem;
  align-self: flex-start;
}

main.portal-privacidade > .form button {
  width: 100%;
  margin-top: 2rem;
  font-size: 1.2rem;
}

main.portal-privacidade > .form h2 {
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

main.portal-privacidade > .form .option-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  column-gap: 10px;
  row-gap: 10px;

  margin-bottom: 1rem;
}

main.portal-privacidade > .form .paragraphs p {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

main.portal-privacidade > .form .option-grid .option {
  font-size: 0.85rem;

  width: 100%;
  padding: 2rem 0.5rem;

  border: 1px solid #cecece;

  cursor: pointer;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  font-weight: 600;

  transition: background-color 300ms, color 300ms;
}

main.portal-privacidade > .form .option-grid .option.selected {
  background-color: #003399;
  color: white;
  border: 1px solid #003399;
}

main.portal-privacidade > .form .file-span {
  display: block;
  margin-bottom: 0.5rem;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  color: #003399;
}

main.portal-privacidade > .form .file-about-span {
  font-size: 0.9rem;
  color: #71717a;
  font-style: italic;
}

@media screen and (min-width: 640px) {
  main.portal-privacidade > .form .option-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  main.portal-privacidade > .form .option-grid .option {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  main.portal-privacidade > .form .option-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
