.section-inner {
  padding: 0 0.7rem;
}

.linha-formulario:hover {
  background-color: rgba(196, 196, 196, 0.2);
}

@media (max-width: 600px) {
  .section-inner.linha-formulario {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    padding: 1rem 0.7rem !important;
  }
}
