.botao-direita{
    display: flex;
    justify-content: flex-end;
    padding:  1rem;
}
.botao-esquerda{
    display: flex;
    justify-content: flex-start;
    padding: 1rem ;
}
.botao-esquerda-voltar{
    display: flex;
    justify-content: flex-start;
    padding:0 1rem 1rem 1rem ;
}