
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


.all-page {

    margin: 0;
    padding: 0;
    align-items: center;    
    padding: 3rem 2%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.background-container {
    background-image: url("../../../assets/images/formulario-covid-background.svg");
    background-size: cover;
    background-repeat: no-repeat;

  }
  

body{
    background-image: cover;
}

.titulo {
    font-size: 2.3rem;
    text-align: center;
}

.cabecalho {
    position: fixed;
    top: 0;
    background-color: #91a1fa;
    width: 150%;
    color: #232424;   
    height: 3rem;
    width: 80%;
    border-radius: 0 0 5px 5px;
    z-index: 10;
}

.principal-rel{    
    padding: 1em;
    margin: 1em;
    /* margin-top: 2em; */
    
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6), 0px 4px 6px rgba(0, 0, 0, 0.1);
    
    width:70%;
}



